.kar-checkbox input[type="checkbox"] {
    display: none;
}

.kar-checkbox label {
    padding-top: 15px;
}

.kar-checkbox:before {
    content: "\f00c"; /* This is the specific unicode for the icon */
    float: left;
    font-family: "FontAwesome";
    font-size: 20px;
    width: 40px;
    height: 40px;
    min-width: 40px;
    max-height: 40px;
    cursor: pointer;
    border-radius: 50%;
    display: block;
    background-color: rgba(0, 0, 0, 0.25);
    margin: 3px;
    text-align: center;
    padding-top: 5px;
    -moz-transition: all 0.15s cubic-bezier(0.5, 0, 0, 1.5);
    -o-transition: all 0.15s cubic-bezier(0.5, 0, 0, 1.5);
    -webkit-transition: all 0.15s cubic-bezier(0.5, 0, 0, 1.5);
    transition: all 0.15s cubic-bezier(0.5, 0, 0, 1.5);
}

.kar-checkbox:before:hover {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
}

.kar-checkbox.checked:before {
    background-color: #a5c25c; /* Adjust the color of the background for your icons */
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    font-size: 20px;
    color: white;
}

.kar-checkbox.checked:before:hover {
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

